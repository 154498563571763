<script setup lang="ts">
import type {ContentItem} from "~/types/seo/ContentItem";

defineProps<{
  content: ContentItem
}>()

const open = ref(false)
const handleMore = () => open.value = !open.value
</script>

<template>
  <div
      class="w-full relative flex flex-col gap-5 lg:gap-2"
      :class="open ? '' : 'max-h-[400px] overflow-hidden'"
  >
    <h2 class="text-sm md:text-xl text-gray-700 tracking-tight font-semibold">{{content.title_fa}}</h2>
    <div
        id="introduction"
        class="w-full flex flex-col text-gray-800 lg:text-justify lg:gap-5 leading-9 rounded tracking-tight text-xs"
        v-html="content.content"
    ></div>

    <div
        class="w-full absolute  h-[100px] flex justify-center items-center"
        :class="open ? '-bottom-16' : 'backdrop-blur-sm bottom-0'"
    >
      <u-button
          @click="handleMore"
          :label="open ? 'نمایش کمتر' : 'نمایش بیشتر'"
          variant="ghost"
          size="xl"
      />
    </div>
  </div>
</template>
